import React, { useEffect, useState } from 'react';
import { Header } from '../common/Header/Header';
import { HeadTitle } from '../common/HeadTitle/PageTitle';
import { SimpleCheckbox } from '../common/CheckBox/CheckBox';
import { WorkerListItem } from "./WorkerListItem/WorkerListItem";
import { key } from "../../store/api";
import { TopSidebarButton } from "../TopSidebarButton/TopSidebarButton";
import { Fallback } from "../helpers/Fallback/Fallback";
import { NoData } from "../common/Nodata/NoData";
import { observer } from "mobx-react-lite";
import { ListItem } from "@material-ui/core";
import WorkersPageStore from "../../store/WorkersPage/WorkersStore";
import { useDispatch } from "react-redux";
import { getFullRolesList } from "../../redux/reducers/directory/actions/actions";
import { MyPagination } from "../helpers/MyPagination/MyPagination";
import { ApplicationsStore } from "../../store/applications/Applications";
import { WorkerListItemCard } from './WorkerListItem/WorkersListItemCard';

const s = require('./style.module.scss');

interface IWorkersComponentProps {

    [key: string]: any
}

export const Workers: React.FC<IWorkersComponentProps> = observer(({ }) => {


    const PAGE_SIZE = 50
    const [page, setPage] = useState<number>(1)

    // const isMobile: boolean = window.innerWidth <= 1024
    const [widthOfScreen, setWidthOfScreen] = useState<number>(window.innerWidth)
    const [isMobile, setIsMobile] = useState<boolean>(widthOfScreen <= 1024 ? true : false)

    useEffect(() => {
        window.addEventListener("resize", handleResize);
        return () => {
            window.removeEventListener("resize", handleResize);
        };
    }, []);

    useEffect(() => {
        widthOfScreen <= 1024 ? setIsMobile(true) : setIsMobile(false)
    }, [widthOfScreen, window.innerWidth])

    const handleResize = () => {
        setWidthOfScreen(window.innerWidth);
    };

    

    const [selectedWorkers, setSelectedWorkers] = useState<{ workerId: number }[]>([])


    const { requestWorkersListOrItem, workersLoading, workers, workersCount } = WorkersPageStore

    const [loadCount, setLoadCount] = useState<number>(10)

    const [searchValue, setSearchValue] = useState<string>('')

    const requestHandler = (searchValue: string) => {
        requestWorkersListOrItem({
            id: null,
            offset: PAGE_SIZE * (page - 1),
            count: PAGE_SIZE,
            searchValue: searchValue === '' ? null : searchValue,
            flag: 'withoutGroups'
        })
    }

    useEffect(() => {
        requestHandler(searchValue)
    }, [page])

    const dispatch = useDispatch()
    useEffect(() => {
        dispatch(getFullRolesList({}))
        if (!localStorage.getItem(key.main)) {
            // window.location.href = '/authorization'
        } else {
            requestHandler(searchValue)
        }
    }, [])

    const [checked, setChecked] = useState<boolean>(false)


    return (
        <div className={s.main_page_wrap}>
            <Header searchValue={searchValue} setSearchValue={setSearchValue}
                request={(value) => requestHandler(value)} />
            <div className={s.title_wrap}>
                <HeadTitle title={'Сотрудники'} />
                <div>
                    <TopSidebarButton requestHandler={() => requestHandler(searchValue)} selectedWorkers={selectedWorkers} flag={'employees'} />
                </div>
            </div>
            {workersLoading
                ? <div style={{ display: 'flex', alignItems: 'center', height: 750 }}>
                    <Fallback>Пожалуйста подождите, идёт загрузка сотрудников</Fallback>
                </div>
                : <div className={s.items}>
                    {!isMobile && <div className={s.title_content}>
                        <div style={{ flex: '0 0 3%' }}>
                            <SimpleCheckbox
                                value={checked}
                                onChange={() => setChecked(!checked)}
                            />
                        </div>
                        <div style={{ flex: '0 0 23%' }}>ФИО</div>
                        {/* <div style={{ flex: '0 0 17%' }}>Роль</div> */}
                        <div style={{ flex: '0 0 10%' }}>Группа</div>
                        <div style={{ flex: '0 0 10%' }}>Телефон</div>
                        <div style={{ flex: '0 0 5%' }}>В работе</div>
                        <div style={{ flex: '0 0 10%' }}>Просрочено</div>
                        <div style={{ flex: '0 0 1%' }}></div>
                    </div>}
                    {workers && workers.length > 0 ? workers.map((i) => {
                        if (isMobile) return <WorkerListItemCard
                            searchHandler={() => requestHandler(searchValue)}
                            selectedWorkers={selectedWorkers}
                            addWorker={(workerId: number) => setSelectedWorkers([...selectedWorkers, { workerId: workerId }])}
                            deleteWorker={(workerId: number) => {
                                setSelectedWorkers(selectedWorkers.filter(i => i.workerId !== workerId))
                            }}
                            allChecked={checked}
                            key={i.id} worker={i} />
                        else return <WorkerListItem
                            searchHandler={() => requestHandler(searchValue)}
                            selectedWorkers={selectedWorkers}
                            addWorker={(workerId: number) => setSelectedWorkers([...selectedWorkers, { workerId: workerId }])}
                            deleteWorker={(workerId: number) => {
                                setSelectedWorkers(selectedWorkers.filter(i => i.workerId !== workerId))
                            }}
                            allChecked={checked}
                            key={i.id} worker={i} />
                    }) :
                        <NoData text={'Сотрудники'} />}
                    {/* <div className={s.btn_download}>
                        <ListItem
                            button
                            style={{
                                width: 150,
                                border: '1px solid #CED4DA',
                                background: '#fff',
                                borderRadius: 5,
                                display: 'flex',
                                justifyContent: 'center',
                                alignItems: 'center',
                            }}
                            onClick={() => {
                                setLoadCount(loadCount + 5)
                                requestWorkersListOrItem({
                                    id: null,
                                    offset: 0,
                                    count: loadCount,
                                    searchValue: null,
                                    flag: 'withoutGroups'
                                })
                            }}
                        >
                            Загрузить ещё
                        </ListItem>
                    </div>*/}
                    <MyPagination
                        onChange={setPage}
                        total={workersCount || 0}
                        currentPage={page}
                        pageSize={PAGE_SIZE}
                    />
                </div>
            }

        </div>
    )
})
