// @ts-nocheck
import React from 'react'
import { ISimpleTreeSelectProps } from "./types";
import clsx from "clsx";
import { TreeSelect } from "antd";
import { ArrowIcon } from "../../../icons/icons";
import { ErrorText } from "../ErrorText/Error";
import { observer } from 'mobx-react-lite';


const classes = require('./selects.module.scss')
export const SimpleTreeSelect: React.FC<ISimpleTreeSelectProps> = ({
    width = '100%',
    defaultValue,
    isControlled = false,
    placeholder = 'Выберите',
    items,
    onChange,
    showSearch = false,
    size = 'lg',
    mode,
    treeCheckable,
    ...props
}) => {
    const { error, invalid, value } = props;

    let a: any = []


    const classNames = clsx(
        classes.simple_select,
        size === 'lg' && classes.lg,
        size === 'md' && classes.md,
        size === 'xs' && classes.xs
    )
    if (mode === 'view') {
        return <span>{items.find(item => item.id === value)?.name || ''}</span>
    }
    return (
        <div style={{ width }}>
            <TreeSelect
                allowClear
                value={value}
                getPopupContainer={trigger => trigger.parentNode}
                treeData={items}
                treeDataSimpleMode
                className={classNames}
                treeCheckable={treeCheckable}
                popupClassName={classes.dropdown}
                suffixIcon={!props?.loading
                    ? <ArrowIcon style={{ transform: 'rotate(90deg)', width: 6, height: 10 }} />
                    : undefined
                }
                showSearch={showSearch}
                defaultValue={defaultValue}
                style={{
                    width,
                    border: invalid ? '1px solid #DF0E0F' : '',
                    borderRadius: 4,
                }}
                maxTagCount={"responsive"}
                placeholder={placeholder}
                optionfilterprop="children"
                onChange={(value, option) => {
                    items.map(item => {
                        if (Array.isArray(value)) {
                            if (value.find(val => val === item.name)) {
                                a.push(item.id)
                            } else if (value.find(val => val === item.id)) {
                                a.push(item.id)
                            }
                        }
                    })
                    onChange(Array.isArray(value) ? a : value, option)
                }}
                loading={props?.loading}
                {...props}
            />
            {/* <ErrorText error={error} /> */}
        </div>
    )
}