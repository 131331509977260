import React, { useEffect, useState } from "react";
import { observer } from "mobx-react-lite";
import { Header } from "../common/Header/Header";
import { HeadTitle } from "../common/HeadTitle/PageTitle";
import { TopSidebarButton } from "../TopSidebarButton/TopSidebarButton";
import { Fallback } from "../helpers/Fallback/Fallback";
import { NoData } from "../common/Nodata/NoData";
import { ApplicationsStore } from "../../store/applications/Applications";
import { HistoryListItem } from "./HistoryListItem/HistoryListItem";
import { MyPagination } from "../helpers/MyPagination/MyPagination";
import { RangeDatePicker } from "../NewApplication/DatePicker/DatePickerComponent";
import moment, { Moment } from "moment";
import { ListItem } from "@material-ui/core";
import { formatIsoToDate } from "../helpers/utils";
import { HistoryListItemCard } from "./HistoryListItem/HistoryListItemCard";

const s = require('./History.module.scss')

interface IHistoryProps {

}

export const HistoryPage: React.FC<IHistoryProps> = observer(({ }) => {

    const isMobile: boolean = window.outerWidth <= 425

    const PAGE_SIZE = 50
    const [page, setPage] = useState<number>(1)

    const { applications, applicationsLoading, requestApplications } = ApplicationsStore

    const [loadCount, setLoadCount] = useState<number>(12)

    const [searchValue, setSearchValue] = useState<string>('')

    const [dateStart, setDateStart] = useState<string | undefined>(undefined)
    const [dateEnd, setDateEnd] = useState<string | undefined>(undefined)

    //Запрос за заявки
    const requestTasksHandler = (searchValue: string) => {
        requestApplications({
            id: null,
            name: null,
            offset: PAGE_SIZE * (page - 1),
            count: PAGE_SIZE,
            flag: 'history',
            searchValue: searchValue === '' ? null : searchValue,
            filters: null,
            dateHistFrom: dateStart ? formatIsoToDate(dateStart) : null,
            dateHistTo: dateEnd ? formatIsoToDate(dateEnd) : null
        })
    }

    useEffect(() => {
        requestTasksHandler('')
    }, [])
    useEffect(() => {
        requestTasksHandler(searchValue)
    }, [page])




    return <div className={s.main_page_wrap}>
        <Header searchValue={searchValue} setSearchValue={setSearchValue} request={(value) => {
            requestTasksHandler(value)
        }} />
        <div className={s.title_wrap}>
            <HeadTitle title={'История'} />
            <div>
                <TopSidebarButton selectedWorkers={[]} flag={'history'} />
            </div>
        </div>
        <div className={s.filters}>
            <RangeDatePicker
                width={isMobile ? '100%' : '80%'}
                onChange={(value: any) => {
                    if (value?.length === 2) {
                        setDateStart(moment(value[0]).format())
                        setDateEnd(moment(value[1]).format())
                    } else {
                        setDateStart(undefined)
                        setDateEnd(undefined)
                    }
                }}
                value={undefined}
            />
            <ListItem
                button
                onClick={() => requestTasksHandler(searchValue)}
                style={{
                    width: isMobile ? '100%' : 90,
                    marginTop: isMobile ? 4 : 'unset',
                    height: 37,
                    borderRadius: 4,
                    background: 'black',
                    color: 'white',
                    marginLeft: 6,
                    display: 'flex',
                    alignItems: 'center',
                    justifyContent: 'center'
                }}
            > Поиск</ListItem>
        </div>
        {applicationsLoading
            ? <div style={{ display: 'flex', alignItems: 'center', height: 750 }}>
                <Fallback>Пожалуйста подождите, идёт загрузка истории</Fallback>
            </div>
            : <div className={s.content}>
                {!isMobile && <div className={s.title_content}>
                    <div style={{ flex: '0 0 5%' }}>№</div>
                    <div style={{ flex: '0 0 10%' }}>Дата</div>
                    <div style={{ flex: '0 0 45%' }}>Исполнитель(-и)</div>
                    <div style={{ flex: '0 0 15%' }}>Заказчик</div>
                    <div style={{ flex: '0 0 10%' }}>Статус</div>
                    <div style={{ flex: '0 0 15%' }}>Контактный телефон</div>
                </div>}
                {applications && applications?.value?.length > 0 && applications.value.map((i) => {
                    if (isMobile) return <HistoryListItemCard
                        item={i} />
                    else return <HistoryListItem
                        item={i} />
                })}
                {/*<div className={s.btn_download}>
                    <ListItem
                        button
                        style={{
                            width: 150,
                            border: '1px solid #CED4DA',
                            background: '#fff',
                            borderRadius: 5,
                            display: 'flex',
                            justifyContent: 'center',
                            alignItems: 'center',
                        }}
                        onClick={() => {
                            setLoadCount(loadCount + 5)
                            requestApplications({
                                id: null,
                                name: null,
                                offset: 0,
                                count: loadCount,
                                flag: 'history',
                                searchValue: null,
                                date: null,
                                filters: null
                            })
                        }}
                    >
                        Загрузить ещё
                    </ListItem>
                </div>*/}
            </div>}
        {((applications?.value && applications?.value?.length === 0) || !applications?.value) && <NoData text={'История'} />}
        <MyPagination
            onChange={setPage}
            total={applications?.count || 0}
            currentPage={page}
            pageSize={PAGE_SIZE}
        />
    </div>
})