import { instance } from ".";
import {openNotification} from "../../components/helpers/notification";

export type TApiError = {
    errorCode: string
    errorMessage: string
    index?: number
}


export function checkError(err: any) {
    let response: TApiError[] | undefined = getResponseErrorMessages(err)
    if (!response) {
        openNotification({type: 'error', message: err.message || 'Неизвестная ошибка'})
    }
    response = response?.length
        ? response?.map((r, index) => ({...r, index}))
        : []
    response.map(r => openNotification({type: "error", message: r.errorMessage}))
}

export function getResponseErrorMessages(err: any) {
    let errorMessages: TApiError[] = err.response?.data?.errorMessages
    if (!errorMessages?.length) {
        return undefined;
    } else {
        return errorMessages;
    }
}

export const isError = async (error: any) => {
    if (error?.response?.status === 401) {
        try {
            const res = await instance().close().getRefreshToken()
            const token = res.data.access_token
            const refreshToken = res.data.access_token_refresh
            if (token && refreshToken) {
                localStorage.setItem('CLIENT_TOKEN', token)
                localStorage.setItem('CLIENT_TOKEN_REFRESH', refreshToken)
                window.location.reload()
            } 
            else {
                localStorage.clear()
                if (localStorage.length === 0) {
                    window.location.href = '/authorization';
                }
            }
        } 
        catch (error) {
            localStorage.clear()
                if (localStorage.length === 0) {
                    window.location.href = '/authorization';
                }
        }
    }
}