import React, { useEffect, useState } from "react";
import { IModal } from "../../../../store/sidebar/types.data";
import { observer } from "mobx-react-lite";
import { Dialog, ListItem } from "@material-ui/core";
import { ModalHeader } from "../../../common/Header/ModalHeader/ModalHeader";
import WorkersPageStore from "../../../../store/WorkersPage/WorkersStore";
import { IWorker } from "../../../../store/WorkersPage/types";
import { openNotification } from "../../../helpers/notification";
import { MultipleSelect } from "../../../helpers/Selects/MultipleSelect";
import { useDispatch, useSelector } from "react-redux";
import {
    getDirectoryGroups,
    getDirectoryRoles,
    getFormviews,
    getFullRolesList
} from "../../../../redux/reducers/directory/actions/actions";
import { TRootState } from "../../../../redux/store";
import { SimpleSelect } from "../../../helpers/Selects/SimpleSelect";
import { convertDataToSelect, convertDataToTreeSelect } from "../../../helpers/converts";
import { useStyles } from "../../../helpers/Styles/CommonStyles";
import { SimpleTreeSelect } from "../../../helpers/Selects/SimpleTreeSelect";
import { TEnumItem } from "../../../helpers/utils";
import { MultipleTreeSelect } from "../../../helpers/Selects/MultipleTreeSelect";
import { action } from "mobx";

const s = require('./style.module.scss')

interface IEditWorkerModalProps extends IModal {
    worker: IWorker
    searchHandler: () => void
}

export const EditWorkerModal: React.FC<IEditWorkerModalProps> = observer(({
    closeHandler,
    open,
    title,
    worker,
    searchHandler
}) => {

    const dispatch = useDispatch()

    const { requestIudWorker, requestWorkersWithGroups, workersWithGroups } = WorkersPageStore
    const roleList = useSelector((state: TRootState) => state.directoryReducer.rolesList)
    const [roles, setRoles] = useState<number[]>([])
    const [actions, setActions] = useState<(string | number)[] | undefined>([])
    const [group, setGroup] = useState<number | undefined>(undefined)
    const [pages, setPages] = useState<(string | number)[] | undefined>([])
    const [itemsOfActions, setitemsActions] = useState<TEnumItem[] | undefined>(undefined)
    const [itemsOfPages, setitemsOfPages] = useState<TEnumItem[] | undefined>(undefined)
    const [proverka, setProverka] = useState([])

    const isMobile: boolean = window.outerWidth <= 425

    const common = useStyles()

    const formviews = useSelector((state: TRootState) => state.directoryReducer.formviews)
    const myInfo = useSelector((state: TRootState) => state.authReducer.myInfo)


    useEffect(() => {
        dispatch(getDirectoryRoles({}))
        requestWorkersWithGroups({
            flag: 'groups'
        })
    }, [])

    useEffect(() => {
        worker?.roles?.length > 0 && setRoles(worker?.roles?.map(i => {
            return +i?.id
        }))
        worker?.group?.id && setGroup(+worker?.group?.id)
    }, [worker])

    const submitHandler = () => {
        // const fff = actions.filter((el:any) => el !== false).concat(pages)
        // console.log(fff)
        // debugger
        worker?.id && requestIudWorker({
            flag: 'U',
            formviews: actions?.concat(pages).filter((el:any) => el !== false).map((element) => { return { id: element } }),
            group: {
                id: group
            },
            id: worker.id
        }, () => {
            openNotification({
                type: "success",
                message: 'Успешно !'
            })
            searchHandler()
            closeHandler()
        })
    }

    // useEffect для подстановки данных с бэка для Действий и Страниц
    useEffect(() => {
        let dataForActions = worker?.formviews && worker?.formviews
            .filter((element: { is_inherited: boolean; }) => element.is_inherited !== true)
            // .filter((element: { is_inherited: boolean; }) => element.is_inherited !== false)
            .filter((element: { type_id: number; }) => element.type_id === 1)
            .map((element: { id: string | number; }) => element.id)
        setActions(dataForActions == null ? [] : dataForActions)
        setProverka(dataForActions == null ? [] : dataForActions)
        debugger

        let dataForPages = worker?.formviews && worker?.formviews
            .filter((element: { is_inherited: boolean; }) => element.is_inherited !== true)
            // .filter((element: { is_inherited: boolean; }) => element.is_inherited !== false)
            .filter((element: { type_id: number; }) => element.type_id === 2)
            .map((element: { id: string | number; }) => element.id)
        setPages(dataForPages == null ? [] : dataForPages)
    }, [])


    useEffect(() => {
        dispatch(getFormviews({
            contractorId: worker.id,
            typeId: 0
        }))
    }, [])



    useEffect(() => {
        const dataForPages = formviews.filter(element => element.type_id === 2).map(element => {
            if (element.is_inherited === true) {
                return {
                    ...element,
                    name: element.name + ' (Наследуется)'
                };
            } else {
                return element;
            }
        })
        setitemsOfPages(dataForPages)
        const dataForActions = formviews.filter(element => element.type_id === 1)
            .map(element => {
                if (element.is_inherited === true) {
                    return (
                        {
                            ...element, name: element.name + ' (Наследуется)'
                        }
                    )
                } else {
                    return ({ ...element })
                }
            })
        setitemsActions(dataForActions)
    }, [formviews])

    useEffect(() => {
      console.log(actions)
      console.log(pages)
      debugger
    }, [actions])

    return <Dialog open={open} classes={{ paper: common.dialogWithoutOverflow }}>
        <ModalHeader title={title} closeHandler={closeHandler} />
        <div className={s.body}>
            {/* <div className={s.row}>
                <div className={s.title}>
                    Роли :
                </div>
                <MultipleSelect
                    onChange={setRoles}
                    items={convertDataToSelect(roleList?.roles) || []}
                    width={!isMobile ? 300 : '100%'}
                    values={roles}
                    size={'md'}
                />
            </div> */}
            <div className={s.row}>
                <div className={s.title}>
                    Группа :
                </div>
                <SimpleTreeSelect
                    showSearch
                    onChange={setGroup}
                    items={workersWithGroups ? convertDataToTreeSelect(workersWithGroups) : []}
                    width={!isMobile ? 300 : '100%'}
                    value={group}
                    size={'md'}
                />
            </div>
            <div className={s.row}>
                <div className={s.title}>
                    Полномочия :
                </div>
                <MultipleTreeSelect
                    onChange={setActions}
                    items={itemsOfActions ? convertDataToTreeSelect(itemsOfActions) : []}
                    width={!isMobile ? 300 : '100%'}
                    values={actions}
                    size={'md'}
                />
            </div>
            <div className={s.row}>
                <div className={s.title}>
                    Страницы :
                </div>
                <MultipleTreeSelect
                    onChange={setPages}
                    items={itemsOfPages ? convertDataToTreeSelect(itemsOfPages) : []}
                    width={!isMobile ? 300 : '100%'}
                    values={pages}
                    size={'md'}
                />
            </div>
            <div className={s.buttons}>
                <div className={s.row}>
                    <ListItem
                        button
                        style={{
                            background: 'white',
                            border: '1px solid black',
                            borderRadius: 4,
                            width: isMobile ? '100%' : 90,
                            height: 36,
                            color: 'black',
                            display: 'flex',
                            justifyContent: 'center',
                            marginBottom: isMobile ? 4 : 'unset'
                        }}
                        onClick={closeHandler}
                    >
                        Отмена
                    </ListItem>
                    <ListItem
                        button
                        style={{
                            background: 'black',
                            borderRadius: 4,
                            width: isMobile ? '100%' : 90,
                            height: 36,
                            color: 'white',
                            display: 'flex',
                            justifyContent: 'center'
                        }}
                        onClick={submitHandler}
                    >
                        Сохранить
                    </ListItem>
                </div>
            </div>
        </div>
    </Dialog>
})