import React, { useEffect, useState } from 'react';
import { SimpleCheckbox } from "../../common/CheckBox/CheckBox";
import { IWorker } from "../../../store/WorkersPage/types";
import { observer } from "mobx-react-lite";
import WorkersPageStore from "../../../store/WorkersPage/WorkersStore";
import { ArrowIcon, EditIcon } from "../../../icons/icons";
import { TEnumItem } from "../../helpers/utils";
import { useDispatch, useSelector } from "react-redux";
import { getFullRolesList } from "../../../redux/reducers/directory/actions/actions";
import { TRootState } from "../../../redux/store";
import { openNotification } from "../../helpers/notification";
import { EditWorkerModal } from "./editModal/EditWorkerModal";

const s = require('./cardStyle.module.scss');

interface IWorkerListItemCardProps {
    worker: IWorker
    allChecked: boolean
    addWorker: (workerId: number) => void
    deleteWorker: (workerId: number) => void
    selectedWorkers: any
    searchHandler: () => void

    [key: string]: any
}

export const WorkerListItemCard: React.FC<IWorkerListItemCardProps> = observer(({
    worker,
    allChecked,
    deleteWorker,
    addWorker,
    selectedWorkers,
    searchHandler
}) => {


    const [checked, setChecked] = useState<boolean>(false)
    const [seeRoles, setSeeRoles] = useState<boolean>(false)
    const [workerRole, setWorkerRole] = useState<TEnumItem[]>(worker?.roles || [])

    const [editMode, setEditMode] = useState<boolean>(false)

    useEffect(() => {
        setChecked(checked)
    }, [checked])


    const fullRolesList = useSelector((state: TRootState) => state.directoryReducer.fullRolesList)
    const loading = useSelector((state: TRootState) => state.directoryReducer.loadings.fullRolesListLoading)

    const { requestIudWorker, requestWorkersListOrItem } = WorkersPageStore


    return (
        <div className={s.content + ' ' + (checked && s.checked)} onClick={() => {
            if (!checked) {
                setChecked(!checked)
                addWorker(worker.id)
            } else {
                setChecked(!checked)
                deleteWorker(worker.id)
            }
        }}>
            <div className={s.item}>
                <div className={s.title}>
                    Имя:
                </div>
                <div className={s.value}>
                    {worker?.name}
                </div>
            </div>
            {/* <div className={s.item}>
                <div className={s.title}>
                    Роли:
                </div>
                <div className={s.value}>
                    {workerRole && workerRole.length > 0 && workerRole?.map((worker, idx) => {
                        if (idx !== workerRole?.length - 1) {
                            return worker?.name + ', '
                        } else return worker?.name
                    })}
                </div>
            </div> */}
            <div className={s.item}>
                <div className={s.title}>
                    Группа:
                </div>
                <div className={s.value}>
                    {worker?.group?.name || '-'}
                </div>
            </div>
            <div className={s.item}

            >
                <div className={s.title}>
                    Телефон:
                </div>
                <div className={s.value} onClick={(event) => {
                    event.stopPropagation()
                }}>
                    {worker?.phone?.value ? <a href={`tel:${worker?.phone?.value}`}>{worker?.phone?.value}</a> : '-'}
                </div>

            </div>
            <div className={s.item}>
                <div className={s.title}>
                    В работе:
                </div>
                <div className={s.value}>
                    {worker?.work}
                </div>

            </div>
            <div className={s.item}>
                <div className={s.title}>
                    Просроченно:
                </div>
                <div className={s.value}>
                    {worker?.overdue}
                </div>

            </div>
            <div onClick={(event) => {
                event.stopPropagation()
                setEditMode(true)
            }} className={s.item} style={{cursor: 'pointer'}} >
                <EditIcon />
            </div>

            {editMode && <EditWorkerModal
                searchHandler={searchHandler}
                worker={worker}
                closeHandler={() => setEditMode(false)}
                title={'Редактирование сотрудника'}
                open={editMode}
            />}
        </div>
    )
})

