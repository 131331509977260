import React, {useEffect, useState} from 'react';
import {SimpleCheckbox} from "../../common/CheckBox/CheckBox";
import {IWorker} from "../../../store/WorkersPage/types";
import {observer} from "mobx-react-lite";
import WorkersPageStore from "../../../store/WorkersPage/WorkersStore";
import {ArrowIcon, EditIcon} from "../../../icons/icons";
import {TEnumItem} from "../../helpers/utils";
import {useDispatch, useSelector} from "react-redux";
import {getFullRolesList} from "../../../redux/reducers/directory/actions/actions";
import {TRootState} from "../../../redux/store";
import {openNotification} from "../../helpers/notification";
import {EditWorkerModal} from "./editModal/EditWorkerModal";

const s = require('./style.module.scss');

interface IWorkerListItemComponentProps {
    worker: IWorker
    allChecked: boolean
    addWorker: (workerId: number) => void
    deleteWorker: (workerId: number) => void
    selectedWorkers: any
    searchHandler: () => void

    [key: string]: any
}

export const WorkerListItem: React.FC<IWorkerListItemComponentProps> = observer(({
                                                                                     worker,
                                                                                     allChecked,
                                                                                     deleteWorker,
                                                                                     addWorker,
                                                                                     selectedWorkers,
                                                                                     searchHandler
                                                                                 }) => {


    const [checked, setChecked] = useState<boolean>(false)
    const [seeRoles, setSeeRoles] = useState<boolean>(false)
    const [workerRole, setWorkerRole] = useState<TEnumItem[]>(worker?.roles || [])

    const [editMode, setEditMode] = useState<boolean>(false)

    useEffect(() => {
        setChecked(checked)
    }, [checked])


    const fullRolesList = useSelector((state: TRootState) => state.directoryReducer.fullRolesList)
    const loading = useSelector((state: TRootState) => state.directoryReducer.loadings.fullRolesListLoading)

    const {requestIudWorker, requestWorkersListOrItem} = WorkersPageStore



    return (
        <div className={s.content + ' ' + (checked && s.checked) + ' ' + (allChecked && s.checked)}>
            <div className={s.checkbox_wrap} style={{flex: '0 0 3%'}}>
                <SimpleCheckbox
                    value={allChecked || checked}
                    onChange={() => {
                        if (!checked) {
                            setChecked(!checked)
                            addWorker(worker.id)
                        } else {
                            setChecked(!checked)
                            deleteWorker(worker.id)
                        }
                    }}
                />
            </div>
            <div className={s.name_wrap} style={{flex: '0 0 23%'}}>
                {worker?.name}
            </div>
            {/* <div className={s.role_wrap} style={{flex: '0 0 17%'}}>
                <div>
                    {workerRole && workerRole.length > 0 && workerRole?.map((worker, idx) => {
                        if (idx !== workerRole?.length - 1) {
                            return worker?.name + ', '
                        } else return worker?.name
                    })}
                </div>
            </div> */}
            <div className={s.group_wrap} style={{flex: '0 0 10%'}}>
                {worker?.group?.name}
            </div>
            <div className={s.phone_wrap} onClick={(event) => {
                event.stopPropagation()
            }} style={{flex: '0 0 10%', color: '#0275D8'}}>
                <a href={`tel:${worker?.phone?.value}`}>{worker?.phone?.value}</a>
            </div>
            <div className={worker?.work > 0 && s.count} style={{flex: '0 0 5%'}}>
                {worker?.work}
            </div>
            <div className={worker?.overdue > 0 && s.count} style={{flex: '0 0 10%'}}>
                {worker?.overdue}
            </div>
            <div onClick={(event) => {
                event.preventDefault()
                event.stopPropagation()
                setEditMode(true)
            }} className={worker?.overdue > 0 && s.count} style={{flex: '0 0 1%'}}>
                <EditIcon/>
            </div>

            {editMode && <EditWorkerModal
                searchHandler={searchHandler}
                worker={worker}
                closeHandler={() => setEditMode(false)}
                title={'Редактирование сотрудника'}
                open={editMode}
            />}
        </div>
    )
})

